<template>
  <b-overlay :show="isLoading">
    <div>
      <b-row>
        <b-col>
          <h1>{{ $t("styling") }}</h1>
        </b-col>
        <b-col cols="auto">
          <b-button
            class="mb-5 save-button"
            @click="saveAll()"
            :disabled="saveDisabled"
          >
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-row>

      <b-alert variant="warning" :show="saveMessage !== ''">{{
        this.saveMessage
      }}</b-alert>
      <b-row>
        <!-- LOGO  -->
        <b-col>
          <b-form-group
            label-size="lg"
            :label="$t('logo')"
            :description="$t('logoDescription')"
          >
            <ImageUpload
              :url="styling.additional.logo"
              ref="image"
              :title="$t('logo')"
              @updateImage="updateLogo"
            />
          </b-form-group>
        </b-col>

        <!-- BACKGROUND IMAGE  -->
        <b-col>
          <b-form-group
            label-size="lg"
            :label="$t('backgroundImage')"
            :description="$t('backgroundDescription')"
          >
            <ImageUpload
              :url="styling.additional.background"
              ref="image"
              :title="$t('backgroundImage')"
              @updateImage="updateBackground"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FLOATING VIDEO -->
      <b-form-group
        class="mt-5"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        label-cols-lg="2"
        :label="$t('floatingVideo')"
      >
        <b-form-group
          :label="$t('floatingVideo')"
          class="admin-label"
          label-cols-lg="2"
        >
          <b-form-checkbox
            v-model="styling.floatingVideo"
            switch
          ></b-form-checkbox>
        </b-form-group>
      </b-form-group>
      <b-form-group
        class="mt-5"
        label-size="lg"
        label-class="font-weight-bold pt-0"
        label-cols-lg="2"
        :label="$t('headerFooter')"
      >
        <b-form-group
          :label="$t('headerSticky')"
          class="admin-label"
          label-cols-lg="2"
        >
          <b-form-checkbox v-model="styling.stickyHeader" switch>
          </b-form-checkbox>
        </b-form-group>
        <b-form-group
          :label="$t('footerSticky')"
          class="admin-label"
          label-cols-lg="2"
        >
          <b-form-checkbox v-model="styling.stickyFooter" switch>
          </b-form-checkbox>
        </b-form-group>
      </b-form-group>
      <!-- COLORS  -->
      <div>
        <b-form-group
          class="mt-5"
          label-size="lg"
          label-class="font-weight-bold pt-0"
          label-cols-lg="2"
          :label="$t('brandingColors')"
        >
          <b-form-group
            id="fieldset-1"
            :description="$t('choosePrimary')"
            :label="$t('primaryColor')"
            label-for="input-1"
            class="admin-label"
          >
            <b-form-input
              id="input-1"
              v-model="styling.primary"
              type="color"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            :description="$t('chooseSecondary')"
            :label="$t('secondaryColor')"
            label-for="input-1"
            class="admin-label"
          >
            <b-form-input
              id="input-1"
              v-model="styling.secondary"
              type="color"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            :description="$t('chooseTertiary')"
            :label="$t('tertiaryColor')"
            label-for="input-1"
            class="admin-label"
          >
            <b-form-input
              id="input-1"
              v-model="styling.tertiary"
              type="color"
              trim
            ></b-form-input>
          </b-form-group>
        </b-form-group>
        <b-form-group
          label-size="lg"
          label-class="font-weight-bold pt-0"
          label-cols-lg="2"
          :label="$t('textColors')"
        >
          <b-form-group
            id="fieldset-1"
            :description="$t('chooseHeaderText')"
            :label="$t('headerTextColor')"
            label-for="input-1"
            class="admin-label"
          >
            <b-form-input
              id="input-1"
              v-model="styling.text_color_header"
              type="color"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            :description="$t('chooseTitleText')"
            :label="$t('titleTextColor')"
            label-for="input-1"
            class="admin-label"
          >
            <b-form-input
              id="input-1"
              v-model="styling.text_color_title"
              type="color"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            :description="$t('chooseBodyText')"
            :label="$t('bodyTextColor')"
            label-for="input-1"
            class="admin-label"
          >
            <b-form-input
              id="input-1"
              v-model="styling.text_color_body"
              type="color"
              trim
            ></b-form-input>
          </b-form-group>
        </b-form-group>
        <b-form-group
          label-size="lg"
          label-class="font-weight-bold pt-0"
          label-cols-lg="2"
          :label="$t('semanticColors')"
        >
          <b-form-group
            id="fieldset-1"
            :description="$t('chooseSuccess')"
            :label="$t('successColor')"
            label-for="input-1"
            class="admin-label"
          >
            <b-form-input
              id="input-1"
              v-model="styling.success"
              type="color"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            :description="$t('chooseDanger')"
            :label="$t('dangerColor')"
            label-for="input-1"
            class="admin-label"
          >
            <b-form-input
              id="input-1"
              v-model="styling.danger"
              type="color"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group
            id="fieldset-1"
            :description="$t('chooseWarning')"
            :label="$t('warningColor')"
            label-for="input-1"
            class="admin-label"
          >
            <b-form-input
              id="input-1"
              v-model="styling.warning"
              type="color"
              trim
            ></b-form-input>
          </b-form-group>
        </b-form-group>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import ImageUpload from "@/components/admin/ImageUpload.vue";

export default {
  components: {
    ImageUpload,
  },
  data() {
    return {
      saveDisabled: true,
      saveMessage: "",
      busy: false,
      backgroundToggle: true,
      isLoading: false,
    };
  },
  watch: {
    styling: {
      handler() {
        this.saveMessage = "You have unsaved changes!";
        this.saveDisabled = false;
      },
      deep: true,
    },
  },
  computed: {
    styling() {
      return this.$store.state.Styling.styling;
    },
  },
  methods: {
    async saveAll() {
      this.isLoading = true;
      await this.$store.dispatch("Styling/update", this.styling);
      await this.$store.dispatch("Styling/generate");
      this.$root.$bvToast.toast("Styling saved.", { variant: "success" });
      this.saveDisabled = true;
      this.saveMessage = "";
      this.isLoading = false;
    },
    updateLogo(newUrl) {
      this.styling.additional.logo = newUrl;
    },
    updateBackground(newUrl) {
      this.styling.additional.background = newUrl;
    },
  },
  created() {
    this.isLoading = true;
    // this.$store.dispatch("Styling/generate");
    this.saveDisabled = true;
    this.saveMessage = "";
    this.isLoading = false;
  },
};
</script>
<style lang="scss">
.label-checkbox {
  display: flex;
  align-items: center;
}

.image-container {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.styling-image {
  position: absolute;
  max-width: 100%;
  width: 100%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
